/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  right: -250px;
  height: 100vh;
  z-index: 999;
  background: #7386D5;
  transition: all 0.3s;
  background: #fff;
  color: #000000;
  text-transform: uppercase;
  border: 1px solid #E9E9E9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  border-top: none;
  overflow-y: scroll;
}

#sidebar.active {
  margin-right: 250px;
}


#sidebar ul.components {
  padding: 20px 0;
  margin-top: 30px;
}

#sidebar ul li a {
  padding: 15px 20px;
  font-size: 16px;
  display: block;
  transition: all 0.5s;

}

#sidebar ul li a:hover {
  color: #DA2128;
  background: #fff;
  text-decoration: none;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #DA2128;
  background: transparent;
}

.closeIcon {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 10px;
  opacity: .6;
  cursor: pointer;
  outline: none;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
  }
}

.bgTransparent {
  background: transparent;
  border: none;
  outline: none;
}

  .big-circle {
    animation: pulse 0.9s alternate infinite;
    margin: 0 auto;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #da2128;
    opacity: 1.3;
    position: absolute;
    right: 28px;
    top: -5px;
    border: 3px solid #ea7f92;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  }
  
  @keyframes shake {
    0% {
      transform: translate(0%);
    }
    25%, 75% {
      transform: translateY(1%);
    }
    50%, 100% {
      transform: translateX(1%);
    }
  }


.navContact {
  display: none;
}

.links {
  cursor: pointer;
}

.sidebar-list-container {
  padding: 20px;

  .link-container {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;

    a {
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}