/*---- fonts ----*/
@font-face {
  font-family: "ProximaNovaRegular";
  font-display: swap;
  src: url("../assets/fonts/ProximaNovaRegular.eot");
  src: url("../assets/fonts/ProximaNovaRegular.eot") format("embedded-opentype"),
    url("../assets/fonts/ProximaNovaRegular.woff2") format("woff2"),
    url("../assets/fonts/ProximaNovaRegular.woff") format("woff"),
    url("../assets/fonts/ProximaNovaRegular.ttf") format("truetype"),
    url("../assets/fonts/ProximaNovaRegular.svg#ProximaNovaRegular")
      format("svg");
}

@font-face {
  font-family: "ProximaNovaBold";
  font-display: swap;
  src: url("../assets/fonts/ProximaNovaBold.eot");
  src: url("../assets/fonts/ProximaNovaBold.eot") format("embedded-opentype"),
    url("../assets/fonts/ProximaNovaBold.woff2") format("woff2"),
    url("../assets/fonts/ProximaNovaBold.woff") format("woff"),
    url("../assets/fonts/ProximaNovaBold.ttf") format("truetype"),
    url("../assets/fonts/ProximaNovaBold.svg#ProximaNovaBold") format("svg");
}

@font-face {
  font-family: "ProximaNovaSemibold";
  font-display: swap;
  src: url("../assets/fonts/ProximaNovaSemibold.eot");
  src: url("../assets/fonts/ProximaNovaSemibold.eot")
      format("embedded-opentype"),
    url("../assets/fonts/ProximaNovaSemibold.woff2") format("woff2"),
    url("../assets/fonts/ProximaNovaSemibold.woff") format("woff"),
    url("../assets/fonts/ProximaNovaSemibold.ttf") format("truetype"),
    url("../assets/fonts/ProximaNovaSemibold.svg#ProximaNovaSemibold")
      format("svg");
}

@font-face {
  font-family: "ProximaNovaLight";
  font-display: swap;
  src: url("../assets/fonts/ProximaNovaLight.eot");
  src: url("../assets/fonts/ProximaNovaLight.eot") format("embedded-opentype"),
    url("../assets/fonts/ProximaNovaLight.woff2") format("woff2"),
    url("../assets/fonts/ProximaNovaLight.woff") format("woff"),
    url("../assets/fonts/ProximaNovaLight.ttf") format("truetype"),
    url("../assets/fonts/ProximaNovaLight.svg#ProximaNovaLight") format("svg");
}

/* ---- fonts ----*/
html {
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

// Bootstrap SCSS variable to overide reboot.scss
$navbar-dark-color: #fff;
$h1-font-size: 24px;
$h2-font-size: 24px;
$body-color: #333333;
$border-radius: 2px;
$blue: #da2128;
$cyan: #fff;
$font-family-sans-serif: "ProximaNovaRegular";
$font-size-base: 0.875rem;
$font-weight-normal: none;
$nav-tabs-link-active-color: #da2128;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: #da2128;
$nav-tabs-link-hover-border-color: transparent;

// Always import bootstrap after overiding SCSS variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.font-semibold {
  font-family: "ProximaNovaSemibold";
}

.font-bold {
  font-family: "ProximaNovaBold";
}

.font-light {
  font-family: "ProximaNovaLight";
}

.font-regular {
  font-family: "ProximaNovaRegular";
}

.font-weight {
  font-weight: normal !important;
}

.grey-bg {
  background: #fafafa;
}
.redGradient-bg {
  // background: linear-gradient(360deg, #831C29 0%, #B41E25 100%);
  background: linear-gradient(343deg, #231127 -4.89%, #E52629 94.97%);
  color: #fff;
}

.mobile-hidden {
  @media (max-width: 575px) {
    display: none;
  }
}

.desktop-hidden {
  @media (min-width: 576px) {
    display: none;
  }
}

#root {
  overflow-x: hidden;
}

.truncate-2 {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  line-height: 1.4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.cursor-pointer {
  cursor: pointer;
}

.page-content {
  min-height: 500px;
  display: inline-block;
  width: 100%;
}

.page-content-ads {
  min-height: 500px;
  display: inline-block;
  width: 100%;
}

.page-container {
  margin: 0 auto;
  width: auto;
  max-width: 1680px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 31px;
  //overflow: hidden;
}

.sectionCustomMarginTop {
  margin-top: 51px;
}

.sectionCustomMarginTopXL {
  margin-top: 55px;
}

.sectionCustomPaddingBtm {
  padding-bottom: 31px;
}

.sectionBlockCustomMarginTop {
  margin-top: 31px;
}

.marginTop {
  margin-top: 15px;
}

.boxShadowMuted {
  box-shadow: none !important;
}

/* --- prefix for select box default arrrow ---*/
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

/*---- Nav bar ---*/
.navbar-brand {
  width: 126px;
  height: 38px;
  float: left;
  margin-top: 5px;
  padding-top: 0;

  & img {
    width: 126px;
  }
}

.navbar-brand-ads {
  width: 146px;
  height: 45px;
  float: left;
  margin-top: 5px;
  margin-left: 40px;
  padding-top: 0;

  & img {
    width: 100%;
    max-width: 126px;
  }
}

.searchBar {
  flex-grow: 1;
  max-width: 550px;
  height: 45px;
  // display: inline-block;
  background: #fff;
  // margin-left: 88px;

  .location {
    min-width: 110px;
    height: 45px;
    background-color: #f3f3f4;
    line-height: 45px;
    padding: 0 11px;
    position: relative;
  }

  .location select,
  .buySell select {
    display: block;
    width: 100%;
    // margin: 6px 0 0 0;
    padding: 0;
    border: none;
    outline: none;
    appearance: none;
    position: relative;
    z-index: 1;
    background: transparent;
    cursor: pointer;
  }

  .location select + i,
  .buySell select + i {
    width: 11px;
    height: 6px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    right: 11px;
    top: 20px;
  }

  .customFormcontrol {
    background: transparent;
    border: transparent;
    margin-top: 3px;
    padding: 0;

    &:focus {
      color: #333;
      background-color: transparent;
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }

  .buySell {
    width: 88px;
    height: 45px;
    line-height: 45px;
    padding: 0 11px;
    position: relative;
  }

  .sepration {
    width: 1px;
    height: 37px;
    margin: 4px 0;
    background-color: #eee;
  }

  .searchInput {
    /*width: 58%;*/
    // width: 318px;
    height: 45px;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    border-width: 0;
    padding: 0 10px 0 10px;
    float: left;

    &:focus {
      background-color: transparent;
      border-color: transparent;
      outline: 0;
      box-shadow: none;
      color: #333;
    }
  }

  .searchIcon {
    width: 48px;
    height: 45px;
    // float: right;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #dc292f;
    padding: 14px 15px;
    cursor: pointer;
    box-shadow: none;
    border: none;

    .icon {
      background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/search-icon.svg);
      width: 18px;
      height: 18px;
      float: left;
    }
  }

  .elasticSearchList {
    z-index: 999;
    width: 66.75%;
    left: 183px;
    top: 46px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    background: #ffffff;

    .list-group-item {
      background-color: transparent;
      border-bottom: 1px solid #ccc;
      border-right: 0px;
      border-left: 0;
      border-top: 0;
      margin-bottom: 0;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}


.navbarRight {
  // width: 100%;
  // max-width: 520px;
  // width: 567px;
  min-width: fit-content;
  width: 558px;
  height: 45px;
  display: flex;
  align-items: center;
  // padding-top: 6px;
  @media (max-width:1127px) {
    width: 485px;
    
  }
  @media (max-width:1083px) {
    width: fit-content;
    
  }
  .csDetails {
    // text-align: end;
    // width: 100%;
    max-width: 500px;
    padding: 14px;
    @media (max-width:1127px) {
      padding: 8px;

    }
    // padding-bottom: 0px;
    min-width: fit-content;
    margin-right: 10px;
    text-transform: uppercase;
    line-height: 16px;
    position: relative;
    // bottom: 5px;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .csDetails-container{
    margin: 0px;
    justify-content: flex-end;
    width: 100%;
    @media (max-width: 1083px){
      display: none;
    }
    
    
  }

  a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: -5px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  // a:after:not(.active):hover {
  //   background: #b6e1ff;
  // }

  a:hover:after { 
    width: 96%; 
    left: 0px;
  }

  a:active:after { 
    width: 96%;
    left: 0px;
  }

  a:focus:after { 
    width: 96%;
    left: 0px;
  }

  .contactIcon{
    width: 29px;
    margin-bottom: 15px;
  }

  .contactIconMobile {
    display: none;
  }

  .hamburgerIcon {
    width: 30px;
    height: 18px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/hamburger-icon.svg);
    // margin-top: -69px;
    border: none;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
      cursor: pointer;
    }
  }

  // .franchiseMail,
  // .csMail {
  //   width: 140px;
  //   float: right;

  //   .mailIcon {
  //     width: 24px;
  //     height: 16px;
  //     float: left;
  //     margin-right: 7px;
  //     background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/email-icon.svg);
  //   }

  //   a {
  //     color: #ffffff;
  //     text-decoration: none;
  //     cursor: pointer;
  //   }
  // }

  // .csMail {
  //   width: 125px;
  // }

  // .whatsApp {
  //   width: 118px;
  //   float: right;

  //   .Icon {
  //     width: 16px;
  //     height: 16px;
  //     float: left;
  //     margin-right: 7px;
  //     background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Whatsapp-icon.svg);
  //   }

  //   a {
  //     color: #ffffff;
  //     text-decoration: none;
  //     cursor: pointer;
  //   }
  // }

}


.landingPageHeaderRemoval {
  margin-top: -80px;
}

.contactInfo {
  width: 130px;
  float: left;
  color: #fff;
  margin-right: 30px;
  padding-top: 11px;

  .phoneIcon {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/phone-icon.svg);
  }
}

button {
  outline: none !important;
}

.navPhoneIcon {
  width: 18px;
  height: 18px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/nav-phone-icon.svg);
  margin-right: 7px;
}

/* --- credr Promises --- */
#credrPromises {
  .card {
    box-shadow: none;
  }

  .card-body {
    padding: 29px 38px;
  }
}

.expertIcon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/expert-icon.svg);
}

.ribbonIcon {
  width: 65px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/ribbon.svg);
}

.credrCheckIcon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/credr-check.svg);
}

.expertIcon,
.ribbonIcon,
.credrCheckIcon {
  height: 75px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 16px;
  background-size: 100%;
}

.viewStoreBtnCustomMargin {
  margin-top: 20px;
}

// @import "../node_modules/bootstrap/scss/bootstrap";
// @import '../node_modules/font-awesome/less/font-awesome.less';
// @import "node_modules/font-awesome/less/font-awesome.css";

html,
html a {
  -webkit-font-smoothing: antialiased !important;
}

.bg-dark {
  background: #111328 !important;
  height: 76px;
}

.nav-bg {
  background: #111328 !important;
  height: 76px;
}

.nav-ads {
  background-image: linear-gradient(to right, #2e1e4d , #682381);
  height: 100px;
}

.navebarCustom {
  // display: block !important;
  display: flex;
  padding-top: 16px;
  align-items: flex-start;
  @media (max-width:699.98px) {
    display: block;
    
  }
}

body {
  line-height: 19px;
  font-weight: normal;
  font-size: 0.875rem;
}

a {
  color: #333;

  &:hover {
    color: #da2128;
  }
}

.breadcrumb {
  background-color: transparent;
}

.text-muted {
  color: #9d9d9d !important;
}

.header {
  position: fixed;
  z-index: 999;
  width: 100%;

  &.selected {
    position: relative;
    // padding-top: 116px;
  }
}

.wrapper {
  padding-top: 76px;
}

/* -- Nav tabs ---*/
.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs {
  font-family: "ProximaNovaSemibold";
  font-size: 16px;
}

.nav-link {
  padding-right: 0px;
  padding-left: 0px;
  margin: 0 23px;
}

.nav-linkFirstchild {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

/* --- button ----*/
.btn {
  padding: 8px 32px;
  border-radius: 20px;
  text-transform: uppercase;
  font-family: "ProximaNovaSemibold";
}
.btnSecondaryOutline {
  border: 1px solid #da2128;
  color: #958e8e;
  text-transform: uppercase;
  background-color: #ffffff;
}
.btnLogin {
  border: 2px solid;
  padding: 6px 26px 5px 26px;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font15 {
  font-size: 15px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.bikeHdMarginBtm {
  margin-bottom: 2px;
}

/* --- Checkbox ---*/

#checkboxId {
  .MuiIconButton-root {
      //padding: 0px !important;
      padding: 0 5px 0 0 !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #dc292f;
  }
}

/* --- cards ---*/
.card {
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s;
}
@media (max-width: 1300px) {
  .reviewCardPadding{
    padding: 80px;
  }
}

.carBodyMainHd {
  padding-top: 44px;
}

.cardConatiner {
  padding-left: 30px;
  padding-right: 30px;
}

/* ---- Bike list card common class ---*/

.bikeListCard {
  .card {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  .card-body {
    padding: 10px 10px 15px 10px;
  }

  .cardBikeImgWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    img {
      transition: all 0.2s ease-in-out;

      &:hover {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0.7;
      }
    }
  }

  .credrWarranty {
    width: 61px;
    height: 61px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/warrenty.svg);
    position: absolute;
    right: 8px;
    top: 9px;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .credrBfGStore {
    // width: 105px;
    // height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018893618-CredR_Gold_2.svg);
    // position: absolute;
    // right: 8px;
    // top: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    float: right;
    position: relative;
    top: 14px;
  }
  .imgdiv {
    width: 105px;
    height: 30px;
    // // background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018893618-CredR_Gold_2.svg);
    // // position: absolute;
    // // right: 8px;
    // // top: 15px;
    // background-repeat: no-repeat;
    // background-size: 100%;
    float: right;
    position: relative;
    top: 12px;
    @media (max-width: 1200px) {
      width: 80px;
    }
    @media (max-width: 576px) {
      width: 105px;
    }
  }

  .credrpartner {
    // width: 105px;
    // height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018909223-CredR_Plus_1.svg);
    // position: absolute;
    // right: 8px;
    // top: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    float: right;
    position: relative;
    top: 14px;
  }

  .credrDirect {
    // width: 105px;
    // height: 30px;
    background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018821921-CredR_Direct_1.svg);
    // position: absolute;
    // right: 8px;
    // top: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    float: right;
    position: relative;
    top: 14px;
  }

  // .credrGold{

  // }

  .columns {
    padding-right: 0;
    padding-left: 0;
    margin: 0 15px;
  }
}

.credrBfGStore {
  width: 105px;
  height: 30px;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018893618-CredR_Gold_2.svg);
  // position: absolute;
  // right: 8px;
  // top: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  float: right;
  position: relative;
  top: 18px;
}

.credrpartner {
  width: 105px;
  height: 30px;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018909223-CredR_Plus_1.svg);
  // position: absolute;
  // right: 8px;
  // top: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  float: right;
  position: relative;
  top: 18px;
}

.credrDirect {
  width: 105px;
  height: 30px;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018821921-CredR_Direct_1.svg);
  // position: absolute;
  // right: 8px;
  // top: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  float: right;
  position: relative;
  top: 18px;
}

.likes {
  .card {
    box-shadow: none;
  }

  .card-body {
    padding: 10px 10px 15px 10px;
  }

  .cardBikeImgWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .credrWarranty {
    width: 61px;
    height: 61px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/warrenty.svg);
    position: absolute;
    right: 8px;
    top: 9px;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .columns {
    padding-right: 0;
    padding-left: 0;
    margin: 0 15px;
  }
}

.credrWarranty {
  width: 61px;
  height: 61px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/warrenty.svg);
  position: absolute;
  right: 8px;
  top: 9px;
  background-repeat: no-repeat;
  background-size: 100%;
}

/* ---- slick slider common css --- */
.slick-slide img {
  display: inline-block !important;
}

/* ------ popular Used Bikes slider ----- */
#popularUsedBikes {
  .slick-slide {
    padding-right: 30px;
  }

  .slider .slick-prev {
    left: -20px;
  }

  .slider .slick-next {
    right: -20px;
  }

  .cardBikeImgWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .slick-track {
    margin-left: 0;
    margin-right: 0;
  }

  .cardBikeImgWrapper {
    img {
      height: 209px;
      object-fit: cover;
      transition: all 0.2s ease-in-out;

      &:hover {
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0.7;
      }
    }
  }

  .card {
    cursor: pointer;
  }
}

#customerLike {
  .cardBikeImgWrapper {
    img {
      transition: all 0.2s ease-in-out;

      &:hover {
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0.7;
      }
    }
  }
}

/* --- budget Bikes --- */
#budgetBikesTabContent {
  .cardBikeImgWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
}

.budgetBikes {
  cursor: pointer;

  .nav-item {
    div.nav-link {
      padding-right: 0px;
      padding-left: 0px;
      margin: 0 23px;
    }
  }
}

/* ------ you may also like ----- */
#customerLike {
  .slick-slide {
    padding-right: 30px;
  }

  .slider .slick-prev {
    left: -20px;
  }

  .slider .slick-next {
    right: -20px;
  }

  .cardBikeImgWrapper img {
    height: 209px;
    object-fit: cover;
  }
}

#exchangeBikedetails {
  // .slick-slide {
  //   //padding-right: 30px;
  // }

  .slider .slick-prev {
    left: -20px;
  }

  .slider .slick-next {
    right: -20px;
  }

  .cardBikeImgWrapper img {
    height: 285px;
    object-fit: cover;
  }
}

/* ---- filters ---- */
.filterWrapper {
  width: 270px;
  position: sticky;
  height: 100%;
  /*background:#fff;*/
  min-height: 500px;

  .card-body {
    padding: 15px;
  }

  h1 {
    margin-bottom: 0px;
  }

  .citySelect {
    width: 240px;
    height: 35px;
    position: relative;
    border-radius: 2px;
    display: inline-block;
    margin-top: 8px;
  }

  .form-group {
    margin-bottom: 9px;
  }

  .accordion section {
    width: 100%;
    display: inline-block;
    cursor: pointer;
  }

  .accordion form {
    margin-top: 10px;
    max-height: 290px;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 10px;
  }

  .input-group-text {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #979797;
  }

  .brandsearchFormControl {
    border: none;
    border-bottom: 1px solid #979797;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .accordion .arrowDown {
    transform: rotateZ(-180deg);
  }

  .accordion section.collapsed .arrowDown {
    transform: rotateZ(0deg);
  }
}

.bikeDetailsBlock {
  width: calc(100% - 290px);
  float: right;
  min-height: 500px;

  .card-body-custompadding {
    padding: 15px;
    min-height: 500px;
  }

  .icons {
    display: inline-block;
    width: 14px;
    height: 14px;
    text-align: center;
    margin-right: 10px;
  }

}

.hrLine {
  height: 1px;
  background-color: #eaeaea;
  margin: 15px -15px 0 -15px;
}

.badge-success {
  font-weight: normal;
  font-size: 12px;
  background-color: #24b34b;
  font-family: "ProximaNovaRegular";

  i {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/star.svg);
  }
}

.icons {
  display: inline-block;
  width: 14px;
  height: 14px;
  text-align: center;
  margin-right: 10px;
}

.filterIconWrapper {
  display: none;
}

/* --- select Custom css ---*/
select {
  display: block;
  width: 100%;
  margin: 6px 0 0 0;
  padding: 0;
  border: none;
  outline: none;
  appearance: none;
  position: relative;
  z-index: 1;
  background: transparent;
  cursor: pointer;
}

select + i {
  width: 11px;
  height: 6px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow.svg);
  background-repeat: no-repeat;
  position: absolute;
  right: 11px;
  top: 20px;
}

.customFormcontrol {
  background: transparent;
  outline: none;

  &:focus,
  :hover {
    outline: none;
    box-shadow: none;
    background-color: transparent;
    border-color: #cccccc;
  }
}

/* ---- drop-down --- */

.city-dropdown-container {
  width: 100%;
  margin: 0 10px;
}

.city-dropdown {
  &__indicator-separator {
    display: none;
  }

  &__control {
    height: 37px !important;
    min-height: 37px !important;
    border-radius: 0 !important;
  }
}

/* ---- accordian ----*/
.accordion {
  position: relative;

  & a:before {
    content: "";
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow.svg);
    width: 11px;
    height: 6px;
    transition: all 0.5s;
  }

  .card:hover {
    box-shadow: none;
  }
}

.form-check {
  padding-left: 0;
}

.arrowDown {
  width: 11px;
  height: 6px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow.svg);
  background-repeat: no-repeat;
  position: absolute;
  right: 11px;
  top: 8px;
  transition: all 0.5s;
}

.accordian .collapse.show .arrowDown {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* ---- custom check box --- */

[type="checkbox"],
[type="radio"] {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

[type="checkbox"]:hover + label::before,
[type="radio"]:hover + label::before {
  border-color: #979797;
}

[type="checkbox"]:active + label::before,
[type="radio"]:active + label::before {
  transition-duration: 0;
}

[type="checkbox"] + label,
[type="radio"] + label {
  position: relative;
  padding: 6px 6px 6px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="checkbox"] + label::before,
[type="radio"] + label::before {
  background-color: #fff;
  border: 1px solid #979797;
  box-sizing: content-box;
  content: "";
  color: #444;
  margin-right: 10px;
  top: 50%;
  left: 0;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

[type="checkbox"] + label::after,
[type="radio"] + label::after {
  box-sizing: content-box;
  content: "";
  background-color: #979797;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

[type="radio"] + label::after {
  left: 4px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  background-color: #0e902c;
}

[type="checkbox"] + label::before,
[type="checkbox"] + label::after {
  border-radius: 1px;
}

[type="checkbox"] + label::after {
  background-color: transparent;
  top: 46%;
  left: calc(0px + 1px + 18px / 5);
  width: 10px;
  height: 4.8px;
  margin-top: calc(18px / -2 / 2 * 0.8);
  border-style: solid;
  border-color: #0e902c;
  border-width: 0 0 2px 2px;
  border-radius: 0;
  -o-border-image: none;
  border-image: none;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: none;
}

[type="checkbox"]:checked + label::after {
  content: "";
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

[type="radio"] + label::before,
[type="radio"] + label::after {
  border-radius: 50%;
}

[type="radio"]:checked:active + label::before,
[type="radio"]:checked:focus + label::before {
  -webkit-animation: none;
  animation: none;
  -webkit-filter: none;
  filter: none;
  transition: none;
}

[type="radio"]:checked + label::before {
  -webkit-animation: none;
  animation: none;
  background-color: #fff;
}

[type="radio"]:checked + label::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

@-webkit-keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #900;
  }
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #900;
  }
}

/* --- bike detailed page --- */
.pdpLeftBlock {
  width: 600px;
  float: left;
}

.pdpLeftBlockExchange {
  width: 375px;
  float: left;
}

.pdpLeft {
  width: 342px;
  float: left;
}

.whatsAppIcon {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 7px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Whatsapp-icon.svg);
}

.credrStoreIcon {
  width: 185px;
  height: 24px;
  display: inline-block;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Credrstore-img.png);
  background-size: 100%;
  background-repeat: no-repeat;

  @media(max-width: 329px) {
    float: left !important;
    margin-top: 10px;
  }
}

.pdpRightBlock .credrBFGStoreIcon {
  width: 120px;
  height: 30px;
  display: inline-block;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018871218-CredR_Gold_1.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.pdpRightBlock .credrpartnericon {
  width: 120px;
  height: 30px;
  display: inline-block;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018909223-CredR_Plus_1.svg);
  background-size: 100%;
  background-repeat: no-repeat;
position: relative;

}
.inspectionReportbuy{
position: absolute;
top: 90px;
right: 15px;
@media (max-width:699px) {
  top: 60px;
}
}

.pdpRightBlock .credrDirectIcon {
  width: 120px;
  height: 30px;
  display: inline-block;
  background: url(https://s3.ap-south-1.amazonaws.com/credruserdocuments/1650018844124-CredR_Direct_2.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.buyAtHomeImgProduct {
    width: 185px;
    //max-width: 166px;
    position: absolute;
    right: -16px;
    bottom: 66px;
    display: inline-block;
    background-size: 100%;
    background: url('../assets/images/buyathome.svg');
    background-repeat: no-repeat;
}

a.footer-link {
  color: white;
}

.make-model-content {
  background-color: white;
  font-size: 14px;
  font-family: "ProximaNovaLight";
  line-height: 21px;
  padding: 25px 50px;
  text-align: justify;

  @media (max-width: 991px) {
    padding: 25px 40px;
  }

  @media (max-width: 499px) {
    padding: 25px;
  }

  p {
    margin: 0;
  }
}

.meta-why-content {
  // max-width: 800px;
  margin: 0 auto;
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  padding: 20px;
  font-family: "ProximaNovaRegular";
  line-height: 21px;
  text-align: justify;
  font-size: 16px;
  color: #333333;
}
.meta-sellpage-content {
  // max-width: 800px;
  margin: 0 auto;
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  padding: 20px;
  font-family: "ProximaNovaRegular";
  line-height: 21px;
  text-align: justify;
  font-size: 16px;
  color: #333333;
}
.my-anchor-css-class{
  color:red;
}
.pdpRightBlock {
  width: calc(100% - 630px);
  float: right;

  .credrStoreIcon {
    width: 185px;
    height: 24px;
    display: inline-block;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Credrstore-img.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .offerText {
    color: #10792d;
  }

  form {
    max-width: 500px;
    width: 100%;
  }

  .leadForm {
    max-width: 500px;
    width: 100%;
  }

  .bikeDetailsCustom {
    max-width: 500px;
    width: 100%;
  }
}

.pdpRightBlockExchange {
  width: calc(100% - 410px);
  float: right;

  .credrStoreIcon {
    width: 185px;
    height: 24px;
    display: inline-block;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Credrstore-img.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .offerText {
    color: #10792d;
  }

  form {
    max-width: 500px;
    width: 100%;
  }

  .leadForm {
    max-width: 500px;
    width: 100%;
  }

  .bikeDetailsCustom {
    max-width: 500px;
    width: 100%;
  }
}

.bikeDetailsIcons {
  display: inline-block;
  width: 14px;
  height: 14px;
  text-align: center;
  margin-right: 10px;
}

.thumbs-wrapper .control-next.control-arrow:before,
.thumbs-wrapper .control-prev.control-arrow:before {
  display: none;
}

.carousel .thumbs-wrapper {
  text-align: center;
  visibility: hidden;
}

#customerLike .slick-track {
  margin-left: 0px;
  margin-right: 0px;
}

/* --- Materialize input css --- */
.mat-label {
  display: block;
  font-size: 16px;
  transform: translateY(25px);
  color: #333;
  transition: all 0.5s;
}

.mat-input {
  position: relative;
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 0;
  font-size: 16px;
}

select.mat-input {
  padding: 19px 0 8px 0;
}

.mat-div {
  padding: 10px 0 0 0;
  position: relative;
}

.mat-div:after,
.mat-div:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cecece;
  bottom: 0;
  left: 0;
  transition: all 0.5s;
}

.mat-div::after {
  background-color: #10792d;
  transform: scaleX(0);
}

.is-active::after {
  transform: scaleX(1);
}

.is-active .mat-label {
  color: #333;
}

.is-completed .mat-label {
  font-size: 12px;
  transform: translateY(0);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.7 !important;
}

/* ---- Static Pages ----*/
#infoPages {
  ul {
    li {
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
}

.storeLocatorlftBlock {
  width: 200px;
  position: -webkit-sticky;
  position: sticky;
  height: 100%;
  min-height: 500px;

  .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .storeList {
    .list-group-item {
      border-left: 0;
      border-right: 0;

      a {
        text-decoration: none;
      }
    }

    li:first-child {
      border-top: none;
    }
  }

  .nav {
    display: block;
  }
}

.storeLocatorRightBlock {
  width: calc(100% - 215px);

  .phoneIcon {
    width: 12px;
    height: 23px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/mobile-phone.svg);
    margin-right: 10px;
    margin-top: -3px;
  }

  .mapWrap {
    width: 100%;
    height: 225px;
    overflow: hidden;
  }
}

.navPills {
  min-height: 500px;
  margin-top: 30px;
  margin-top: 6px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #da2128;
}

.nav-link {
  padding: 11px 15px;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
}

.filterWrapper .collapse#filterMob {
  display: block;
}

/* --- svg animation --- */
svg {
  width: 60px;
  display: block;
  margin: 0px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/* --- lead popup --- */
.createLeadPopup {
  .modal-header {
    padding: 10px 15px;
    border: none;
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-content {
    border-radius: 0;
  }

  button span {
    font-size: 30px;
  }
}

/* --- Sell Bikes --- */
.sellBikesContent {
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 31px;
}

.sellBikesYourIcon {
  width: 54px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/sellBike-icon.svg);
  background-size: 100%;
}

.sellAllBikesIcon {
  width: 70px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/sellAllBike.svg);
  background-size: 100%;
}

.sellBikesNoPainIcon {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/noPain-icon.svg);
  background-size: 100%;
}

.sellBikesFindUsIcon {
  width: 70px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/findUs.svg);
  background-size: 100%;
}

.serviceConvienceIcon {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Convenience.svg);
  background-size: 100%;
}

.serviceStaff {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Trained-Staff.svg);
  background-size: 100%;
}

.serviceTrust {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Trust.svg);
  background-size: 100%;
}

.serviceStaffChecking {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Staff-Checking.svg);
  background-size: 100%;
}

.serviceContactless {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Contactless-Working.svg);
  background-size: 100%;
}

.serviceSanitization {
  width: 74px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Doorstepservice/Saitisation-and-Disinfecting.svg);
  background-size: 100%;
}

.sellBikesYourIcon,
.sellAllBikesIcon,
.sellBikesNoPainIcon,
.sellBikesFindUsIcon,
.serviceConvienceIcon,
.serviceStaff,
.serviceTrust,
.serviceStaffChecking,
.serviceContactless,
.serviceSanitization {
  height: 75px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 16px;
}

.sellBikesFormWrapper {
  max-width: 890px;
  width: 100%;
  margin: 0px auto;
}

.picky__input {
  padding: 24px 0px 8px 0;
  font-size: 16px;
}

.picky__input::after {
  right: 11px;
  top: 27px;
}

/* --- No Bikes available --- */
.noBikesAvailable {
  max-width: 350px;
  width: 100%;
  margin: 0px auto;
  padding: 50px 5px 50px 5px;

  .noBikesIcon {
    width: 210px;
    height: 225px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/no-bikes-available.svg);
    display: inline-block;
  }
}

.noBikesMMVPuneBtn {
  width: 100%;
  background-color: #25d366;
  border: none;
  padding: 5px 15px 5px 8px;
  font-family: 'ProximaNovaSemibold';
  border-radius: 30px;
  line-height: 1.1;
  color: #ffffff;
  max-width: 280px;
  text-align: right;

  a.value {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
}

.pageLoader {
  width: 150px;
  margin: 0px auto;
  margin-top: 200px;
}

/* --- how it works --- */
.hdContent {
  max-width: 400px;
  margin: 0px auto;
}

.chooseIcon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/choose-icon.svg);
}

.testRideIcon {
  width: 80px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/test-ride-icon.svg);
  top: 10px;
}

.buyNowIcon {
  width: 54px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/buynow-icon.svg);
}

.chooseIcon,
.testRideIcon,
.buyNowIcon {
  height: 75px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 16px;
  background-size: 100%;
  position: relative;
}

.picky__input {
  border-width: 0 !important;
}

.picky__input::after {
  border: none;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow.svg);
  background-repeat: no-repeat;
  width: 11px;
  height: 6px;
}

.trustCustomHide {
  display: none;
}

.brandCard,
.yomMayCard {
  cursor: pointer;
}

.contactUsRightBlock {
  max-width: 500px;

  .conactPhoneIcon {
    width: 28px;
    height: 24px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/contact-phone-icon.svg);
    background-size: 100%;
    margin-right: 10px;
  }

  .conactmailIcon {
    width: 28px;
    height: 21px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/mail-icon.svg);
    background-size: 100%;
    margin-right: 10px;
  }

  .pressIcon {
    width: 22px;
    height: 33px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/press-icon.svg);
    background-size: 100%;
    margin-right: 10px;
    margin-left: 5px;
  }

  .whatsappIcon {
    width: 28px;
    height: 28px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Whatsapp-icon.svg);
    background-size: 100%;
    margin-right: 10px;
  }
}

/* --- franchise page --- */
.support-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/support-icon.svg);
}

.legal-hassle-icon {
  width: 65px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/legal-hassle.svg);
}

.refurbishment-icon {
  width: 70px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/refurbishment-icon.svg);
}

.paperTransfer-icon {
  width: 55px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/paper-transfer-icon.svg);
}

.support-icon,
.legal-hassle-icon,
.refurbishment-icon,
.paperTransfer-icon {
  height: 75px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 16px;
  background-size: 100%;
}

.franchise-graphic {
  max-width: 450px;
}

.locationIndexing-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/map-pin-marked.svg);
}

.deliveryTruck-icon,
.howitworksDeliveryTruck-icon {
  width: 73px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/delivery-truck-icon.svg);
}

.storeGuideline-icon {
  width: 50px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/guidelines-icon.svg);
}

.marketingSupport-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/marketing-support-icon.svg);
}

.onlineDemand-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/line-chart.svg);
}

.accountManger-icon {
  width: 58px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/manager-icon.svg);
}

.trainingDevelopment-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/presentation-icon.svg);
}

.technology-icon {
  width: 60px;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/project-management-icon.svg);
}

.locationIndexing-icon,
.deliveryTruck-icon,
.storeGuideline-icon,
.marketingSupport-icon,
.onlineDemand-icon,
.accountManger-icon,
.trainingDevelopment-icon,
.technology-icon,
.howitworksDeliveryTruck-icon {
  height: 65px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: 16px;
  background-size: 100%;
}

.howitworksDeliveryTruck-icon {
  height: 75px;
  width: 80px;
}

.counts {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1;
  background-size: 100%;
  left: 44%;
  right: 0;
  top: -24px;
  background: #fffdfd;
  border-radius: 50%;
  border: 1px solid #e4e0e0;
  color: #dc292f;
  text-align: center;
  line-height: 42px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
}

.franchiseNavRight {
  display: flex;
  align-items: flex-end;
  width: 510px;

  .contactInfo,
  .franchiseMail,
  .enquiry {
    width: 125px;
    float: left;
    color: #fff;
    margin-right: 10px;
    padding-top: 11px;
  }

  .franchiseMail {
    width: 173px;

    .mailIcon {
      width: 24px;
      height: 16px;
      float: left;
      margin-right: 7px;
      background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/email-icon.svg);
    }

    a {
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .enquiry {
    width: auto;
  }
}

.alertInfo {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  z-index: 99;
}

.alertInfoCustom {
  margin: 0px auto;
  margin-top: 10px;
}

.pulse-loader {
  background: #d3dce2;
  -webkit-animation: Shellpulse 0.65s infinite alternate;
  animation: Shellpulse 0.65s infinite alternate;
  min-height: 200px;
}

.ShellLine40 {
  width: 40%;
  margin-bottom: 10px;
}

.ShellLine40,
.ShellLine90 {
  height: 10px;
  margin-top: 10px;
  // border-radius: 13px;
  background: #d3dce2;
  -webkit-animation: Shellpulse 0.65s infinite alternate;
  animation: Shellpulse 0.65s infinite alternate;
}

.ShellLine90 {
  width: 90%;
}

.ShellLine30 {
  width: 30px;
  height: 10px;
  border-radius: 13px;
}


@media(max-width: 650px) {
  .rtoMob {
    margin-right: 5px;
    padding-left: 15px !important;
  }
}

.btn-outline--small {
  background: transparent;
  outline: none;
  padding: 5px 15px;
  border-radius: 16px;
  margin-bottom: 2px;
  margin-top: 2px;
  border-width: 1px;
  border: 1px solid #191919;

  &:not(:last-child) {
    margin-right: 5px;
    // margin-bottom: 5px;
  }
}  

@-webkit-keyframes Shellpulse {
  0% {
    background-color: #d3dce2;
  }

  to {
    background-color: #e1e9ee;
  }
}

@keyframes Shellpulse {
  0% {
    background-color: #d3dce2;
  }

  to {
    background-color: #e1e9ee;
  }
}

/* --- Thank you page --- */
.thankYouContainer {
  max-width: 900px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .thankyouImg {
    max-width: 300px;
    margin: 0px auto;
  }

  .contentBlock {
    max-width: 670px;
    width: 100%;
    margin: 0px auto;
  }

  .list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }

  .listSeprator {
    max-width: 5px;
  }
}

/* --- checkmark animation --- */

$white: #fff;
$curve: cubic-bezier(0.42, 0, 0.275, 1.155);
$time: 1.4s;

@mixin size($size) {
  width: $size;
  height: $size;
}

.confetti {
  position: absolute;
  animation: grow infinite $time $curve both;

  &:nth-child(1) {
    @include size(12px);
    left: 12px;
    top: 16px;
  }

  &:nth-child(2) {
    @include size(18px);
    left: 122px;
    top: 30px;
  }

  &:nth-child(3) {
    @include size(10px);
    left: 32px;
    top: 97px;
  }

  &:nth-child(4) {
    @include size(20px);
    left: 82px;
    top: -12px;
  }

  &:nth-child(5) {
    @include size(14px);
    left: 125px;
    top: 80px;
  }

  &:nth-child(6) {
    @include size(10px);
    left: 16px;
    top: 16px;
  }

  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      animation-delay: $i * ($time/2);
    }
  }
}

.checkmark-wrap {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmarktic {
  position: relative;
  padding: 30px;
  animation: checkmarktic 5.6s $curve both;
}

.checkmark__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: $white;
}

.checkmark__back {
  animation: rotate 35s linear both infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes checkmarktic {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  10%,
  50%,
  90% {
    opacity: 1;
    transform: scale(1);
  }
}

.informationIcon {
  width: 12px;
  height: 14px;
  display: inline-block;
  background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/notification-icon.svg);
  background-repeat: no-repeat;
  margin-left: 3px;
}

.viewAllBtn {
  position: absolute;
  right: 0;
  bottom: -33px;
  color: #da2128 !important;
  cursor: pointer;
}

#popularUsedBikes {
  position: relative;
}

.SortBy {
  width: 240px;
  height: 35px;
  position: relative;
  border-radius: 2px;
  float: right;

  select {
    margin: 0;

    + i {
      top: 14px;
    }
  }
}

/* ---- sticky footer ---- */
.stickyFooterWrap {
  display: none;
  // height: 50px;

  .stickyFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 50px;
    z-index: 992;
    /*border-top: 1px solid #ddd;*/
    -webkit-box-shadow: 0 -2px 9px -1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -2px 9px -1px rgba(0, 0, 0, 0.1);
    bottom: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .leftBlock {
      width: calc(100% - 50%);
      border-right: 1px solid #eeeeee;
      height: 50px;
      padding: 14px 0 0 10px;

      .sortIcon {
        width: 21px;
        height: 23px;
        background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/sort-by-icon.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 10px;
      }

      span {
        display: inline-block;
        position: relative;
        bottom: 6px;
      }
    }

    .rightBlock {
      width: 50%;
      height: 50px;
      padding: 17px 0 0 10px;

      .filterIconMobs {
        width: 23px;
        height: 18px;
        background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/filter-icon-img.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 10px;
      }

      span {
        display: inline-block;
        position: relative;
        bottom: 4px;
      }
    }
  }
}

/* --- full popup --- */
#filterModal {
  .modal-dialog {
    margin: 0;

    .modal-content {
      border: none;
      border-radius: 0;

      .modal-header {
        border-radius: 0;
        position: fixed;
        width: 100%;
        height: 58px;
        background: #111328;
        color: #ffffff;
        z-index: 1;
        display: inline-block;

        .close {
          color: #ffffff;
          font-size: 35px;
          opacity: 1;
          padding: 13px 15px 11px 15px;
          font-weight: 100;
          text-shadow: none;
          float: left;
          margin-right: 16px;
        }
      }

      .modal-body {
        margin-top: 58px;
      }
    }
  }

  .modal-footer {
    position: fixed;
    width: 100%;
    background: #ffffff;
    z-index: 1;
    bottom: 0;
  }
}

.seeMoreCard {
  max-width: 150px !important;
  margin-top: 20%;
  padding-top: 21px;
  border: 0;
  box-shadow: none;
  height: 150px;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid transparent;

  .seeMoreBikeWrapper {
    text-align: center;
    padding: 20px 0;
  }

  &:hover,
  &:focus {
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    transition: box-shadow 0.3s;
  }
}

/* --- investors --- */
#Investors {
  .titleCustomMargin {
    margin-bottom: 6px;
  }

  .InvestorsIcon {
    width: 114px;
    height: 114px;
    overflow: hidden;
    display: inline-block;
    /*border:5px solid #EFEDED;*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    margin-top: -56px;
    margin-bottom: 20px;
    transition-duration: 0.3s;

    &:hover {
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.07);
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }

  .customLineHeight {
    line-height: 22px;
  }
}

.visibleMob {
  display: none;
}

.priceStrike {
  text-decoration: line-through;
  color: #9d9d9d;
}

.greenColor {
  color: #498404;
}

.blackColor {
  color: #333333;
}

/* --- media queries ---- */
@media (min-width: 1500px) {
  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 290px;
        object-fit: cover;
      }
    }
  }
}



@media (min-width: 1300px) {
  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 235px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1299px) {

  // .credrBfGStore {
  //   top: 20px;
  // }

  .page-container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 212px;
        object-fit: cover;
      }
    }
  }

  .navTabCustom {
    .card-body {
      padding: 15px;
    }
  }

  #popularUsedBikes,
  #customerLike {
    .cardBikeImgWrapper {
      img {
        height: 167px;
        object-fit: cover;
      }
    }
  }

 
}

@media (max-width: 1254px) {
 
  .navbarRight {
  

    
    .hamburgerIcon {
      width: 30px;
      height: 18px;
      background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/hamburger-icon.svg);
      // margin-top: -53px;
      border: none;
      outline: none;
      cursor: pointer;

      &:focus {
        outline: none;
        cursor: pointer;
      }
    }
  }
}



@media (max-width: 1199.98px) {


  .navbar-brand-ads {
    margin-left: 20px;
  }

  .searchBar .elasticSearchList {
    width: 61.78%;
  }

  .bikeListCard .columns {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
  }

  .likes .columns {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
  }

  #serachByBrands .slick-slide {
    padding-right: 15px;
    padding-left: 5px;
  }

  #popularUsedBikes .slick-slide {
    padding-right: 20px;
  }

  #customerLike .slick-slide {
    padding-right: 20px;
  }

  #credrPromises .card-body {
    padding: 30px 15px !important;
  }

  .bikeDetailsBlock {
    .col-sm-6,
    .col-sm-10,
    .col-md-4 {
      padding: 0 10px;
    }
  }

  .filterWrapper {
    width: 230px;
  }

  .bikeDetailsBlock {
    width: calc(100% - 245px);
  }

  .filterWrapper .citySelect {
    width: 196px;
  }

  .page-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .pdpLeftBlock {
    width: 500px;
  }

  .pdpLeftBlockExchange {
    width: 385px;
  }

  .pdpRightBlock {
    width: calc(100% - 530px);
  }

  .pdpRightBlockExchange {
    width: calc(100% - 410px);
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 188px;
        object-fit: cover;
      }
    }
  }

  .navTabCustom {
    .btn {
      padding: 8px 15px;
    }
  }

  #countDown {
    .card-body {
      padding: 1.25rem 0.5rem;
    }

    .font22 {
      font-size: 18px;
    }

    h1 {
      font-size: 25px;
    }

    .ratings-icon {
      width: 110px;
    }
  }

  .countDownWrapper {
    padding: 0 15px;
  }

  .contactUsRightBlock {
    max-width: 410px;
  }
}


@media (max-width: 1069px) {
  .navbar-brand-ads {
    margin-left: 10px;
}

 


}


@media (max-width: 1045px) {
  .navbarRight {
}
.navbarRight .csDetails {
  width: 100%;
  max-width: 480px;
}

}

@media (max-width: 1037px) {

  .navbarRight {
   
    height: 45px;
    .csDetails {
      width: 433px;
    }
  }

}


@media (max-width: 1023px) {
 

  .contactInfo {
    margin-right: 17px;
  }

  .searchBar .elasticSearchList {
    width: 58.33%;
  }

  .page-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .cardConatiner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .filterWrapper {
    width: 100%;
    min-height: auto;
    height: auto;
  }

  .bikeDetailsBlock {
    width: calc(100% - 0px);
  }

  .pdpLeftBlock {
    width: 450px;
  }

  .pdpLeftBlockExchange {
    width: 394px;
  }

  .pdpRightBlock {
    width: calc(100% - 470px);
  }

  .pdpRightBlockExchange {
    width: calc(100% - 420px);
  }

  .filterIconWrapper {
    position: absolute;
    top: 90px;
    right: 20px;
    width: 70px;
    display: block;
  }

  .filterIcon {
    width: 23px;
    height: 20px;
    float: left;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/filter-icon.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 5px;
  }

  .filterWrapper .collapse#filterMob {
    display: none;
  }

  .filterWrapper .collapse.show#filterMob {
    display: block;
  }

  .filterWrapper .accordion form {
    max-height: 209px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 228px;
        object-fit: cover;
      }
    }
  }

  .storeLocatorlftBlock {
    width: 100%;
    position: none;
    min-height: auto;
    height: 45px;
    margin-bottom: 20px;

    .card {
      box-shadow: none;
    }

    .card-body {
      padding-bottom: 0;
    }

    .navPills {
      min-height: auto;

      .nav-link {
        display: inline-block;
        border-bottom: none;
      }
    }
  }

  .storeLocatorRightBlock {
    width: calc(100% - 0px);
  }

  #popularUsedBikes,
  #customerLike {
    .cardBikeImgWrapper {
      img {
        height: 205px;
        object-fit: cover;
      }
    }
  }
}
@media (max-width:1083px) {
  .navbarRight {
  .contactIconMobile{
    display: inline;
    width: 29px;
    // height: 29px;
    margin-right: 45px;
    margin-left: 10px;

  }
}
}

@media (max-width: 1020px) {

  .navbarRight {
   
    height: 45px;

    .csDetails {
      width: 420px;
      padding: 1px;
    }

    .contactIconMobile{
      display: inline;
      width: 29px;
      margin-right: 45px;
      margin-left: 10px;

    }
  }
}



@media (max-width: 1020px) {
  .navbarRight .csDetails {
    display: none;
  }

  .navbarRight .mobileCsDetails {
      display: inline;
  }


}

@media (max-width: 992px) {
  .cardConatiner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bikeListCard .columns {
    padding-right: 0;
  }

  .likes .columns {
    padding-right: 0;
  }

  .contactInfo {
    display: none;
  }

  .navContact {
    display: block !important;
    padding: 15px 20px;
    font-size: 16px;
    color: #333;

    .mailIcon {
      width: 28px;
      height: 21px;
      float: left;
      background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/mail-icon.svg);
      background-size: 100%;
      margin-right: 10px;
    }

    .Icon {
      width: 23px;
      height: 23px;
      float: left;
      background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/Whatsapp-icon.svg);
      background-size: 100%;
      margin-right: 10px;
    }
  }

  .footer .list-group {
    margin-right: 30px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 330px;
        object-fit: cover;
      }
    }
  }

  .contactUsRightBlock {
    max-width: 320px;
  }

  .fdHowitWorksMb {
    margin-bottom: 2rem !important;
  }

  .franchiseNavRight .contactInfo {
    display: block;
  }
}

@media (max-width: 997px) {
  .navbar-brand-ads {
    margin-left: -5px;
  }
}


@media (max-width: 899.98px) {
  .pdpLeftBlock {
    width: 600px;
  }

  .pdpLeftBlockExchange {
    width: 375px;
  }

  .pdpRightBlock {
    width: calc(100% - 0px);
  }

  .pdpRightBlockExchange {
    width: calc(100% - 0px);
    position: relative;
    //bottom: 55px;
  }

  .customCardPadding {
    padding: 24px !important;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 270px;
        object-fit: cover;
      }
    }
  }

  #popularUsedBikes,
  #customerLike {
    .cardBikeImgWrapper {
      img {
        height: 166px;
        object-fit: cover;
      }
    }
  }

  #howItWorks {
    .customCardPadding {
      padding-top: 40px !important;
    }
  }

}

@media (max-width: 1023px) {
  .filterIconWrapper {
    display: none;
  }

  .sortxs-mob {
    display: none;
  }

  .stickyFooterWrap {
    display: block;

    &.careSelec {
      padding-top: 30px;
    }
  }

  #filterModal .modal-body .filterWrapper .collapse#filterMob {
    display: block;
  }

  #filterModal .modal-dialog {
    margin: 0;
    background: #fff;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-bottom: 80px;
    overflow-y: scroll;
  }

  #filterModal {
    .card {
      box-shadow: none;
      border: none;

      .card-body {
        padding: 15px 0px;
      }
    }
  }

  .verloop-button {
    bottom: 55px !important;
    right: 10px !important;
  }
}

@media (max-width: 798px) {
  .btn-none {
    display: none;
  }

  .page-content-ads {
    min-height: 900px;
  }

  .page-content-ads {
    &.selected {
      min-height: 700px;
    }
  }
}

@media (max-width: 767.98px) {
  .bikeDetailsBlock .col-sm-6,
  .bikeDetailsBlock .col-sm-10 .bikeDetailsBlock .col-md-4 {
    padding: 0 10px;
  }


  .xsMob {
    display: none;
  }

  .bikeCategoryCustomMob {
    .font20 {
      font-size: 16px;
    }

    .customCardPadding {
      padding: 15px 10px 15px 10px !important;
    }
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 250px;
        object-fit: cover;
      }
    }
  }

  .pdpDetailsBanner {
    width: 54px !important;
    height: 54px !important;
  }

  .howItCustomMargin {
    margin-bottom: 3rem;
  }

  .trustCustomHide {
    display: block;
  }

  .contactLeft {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    padding-right: 0 !important;
  }

  .contactRight {
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}


#sidebarCollapseNew{
  display: none;
}
.bg-contactIconMobileNew{
  display: none;
  padding: 0px;
  position: absolute;
  top: -48px;
  right: 31px;
}
.contactIconMobileNew{
  width: 29px;
}
@media (max-width: 699.98px) {
.bg-contactIconMobileNew{
  display: block;
}
 #sidebarCollapseNew{
  display: block;
  padding: 0px;
  position: absolute;
  top: -41px;
  right: 0px;
 }

  .landingPageHeaderRemoval {
    margin-top: 0;
}

  .pdpLeftBlock {
    width: 96%;
  }

  .pdpLeftBlockExchange {
    width: 90%;
  }

  .carousel .thumbs-wrapper {
    text-align: center;
  }

  .bg-dark {
    height: 116px;
    padding: 15px;
  }

  .bg-care {
    background: #111328 !important;
    height: 58px;
    padding: 15px;
  }

  .navbar-brand {
    width: 100px;
    height: 31px;
    padding-top: 0;
    margin-top: 0;
    img{
      width: 100px;
      height: 31px;
    }
  }

  .navbar-brand-ads {
    width: 127px;
    height: 36px;
    padding-top: 0;
    margin-top: 5px;
  }

  .navbarRight {
    position: absolute;
    right: 4px;
    top: 6px;
  }


  .searchBar {
    height: 38px;
    width: 450px;
    margin: 0px auto;
    align-self: flex-end;
    margin-top: 49px;

    &.careAppSearch {
      top: 10px;
    }

    .location {
      position: absolute;
      height: 38px;
      top: -51px;
      left: 115px;
      background-color: transparent;
      font-family: "ProximaNovaSemibold";

      .form-control {
        color: #fff;
      }

      select + i {
        background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/down-arrow-white.svg);
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    .buySell {
      height: 38px;
      line-height: 38px;

      select {
        margin-top: 2px;
      }

      select + i {
        top: 17px;
      }
    }

    .sepration {
      height: 30px;
    }

    .searchIcon {
      height: 38px;
      padding: 10px 12px;
    }

    .searchInput {
      width: calc(100% - 110px);
      height: 38px;
    }
  }

  .searchBar .elasticSearchList {
    width: 99.9%;
    left: 0;
    top: 39px;
  }

  #sidebarCollapse {
    padding: 0px;
    margin-right: 10px;
    // position: absolute;
    // top: -41px;
    right: 0px;
    // display: none;
  }



  .mob-xs {
    display: none;
  }

  .slider .slick-next,
  .slider .slick-prev {
    display: none !important;
  }

  .filterIconWrapper {
    top: 128px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 230px;
        object-fit: cover;
      }
    }
  }

  #popularUsedBikes,
  #customerLike {
    .cardBikeImgWrapper {
      img {
        height: 203px;
        object-fit: cover;
      }
    }
  }

  .wrapper {
    padding-top: 116px;

    &.selected {
      padding-top: 0px;
    }

    &.careAppWrapper {
      padding-top: 58px;
    }
  }

  .nav-bg {
    height: 58px;
  }

  .nav-ads {
    height: 70px;
  }

  .franchiseNavRight {
    width: auto;
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 699.98px) {
    .wrapperMob {
      margin-top: -58px;

      &.selected {
        margin-top: 0;
      }
    }
  }

  .franchiseNavRight .enquiry {
    display: none;
  }

  .franchiseNavRight .contactInfo {
    display: none;
  }

  .franchiseNavRight .franchiseMail {
    width: 24px;
  }

  .franchiseNavRight .franchiseEmails {
    display: none;
  }

  .franchiseNavRight .franchiseMail .mailIcon {
    margin-top: -5px;
  }

  .franchiseNavRight {
    width: 68px;
  }

  .pdpRightBlock .credrStoreIcon {
    width: 120px;
  }

  .pdpRightBlockExchange .credrStoreIcon {
    width: 90px;
  }

}

@media (max-width: 599.98px) {
  .footer .addressfooter {
    width: 96%;
    margin-top: 0px;
  }

  .footer .socialIcon {
    margin-top: 15px !important;
    float: left !important;
  }

  .footer .socialIcon .icons {
    width: 30px;
    height: 30px;
    margin-right: 8px;

    img {
      // width: 100%;
      width: 30px;
    }
  }

  .footer .innerBlock {
    width: 92%;
  }

  .homepagePaddingMuted {
    padding-left: 0px;
    padding-right: 0px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 270px;
        object-fit: cover;
      }
    }
  }

  #countDown .facebook-icon,
  #countDown .google-icon {
    width: 30px;
    height: 30px;
  }

  .countDownWrapper .card-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 575.98px) {
  .pdpLeftBlock {
    width: 100%;
  }

  .pdpLeftBlockExchange {
    width: 100%;
  }

  .sectionCustomMarginTop {
    margin-top: 25px;
  }
  .sectionCustomMarginTopXL {
    margin-top: 50px;
  }

  .sectionCustomPaddingBtm {
    padding-bottom: 20px;
  }

  .sectionBlockCustomMarginTop {
    margin-top: 15px;
  }

  h1,
  .h1,
  h2,
  .h2 {
    font-size: 18px;
  }

  .font20 {
    font-size: 16px;
  }

  .font16 {
    font-size: 14px;
  }

  .font18 {
    font-size: 15px;
  }

  /*.container{padding: 0;}*/
  .nav-link {
    margin: 0 10px;
  }

  .nav-linkFirstchild {
    margin-left: 0;
  }

  .carBodyMainHd {
    padding-top: 25px;
    padding-bottom: 0;
  }

  #popularUsedBikes .slick-slide,
  #serachByBrands .slick-slide,
  #customerLike .slick-slide {
    padding-right: 10px;
  }

  .viewStoreBtnCustomMargin {
    margin-top: 5px;
  }

  .list-group-flush {
    margin-bottom: 20px;
  }

  .xsMob {
    display: none;
  }

  .bikeCategoryCustomMob {
    padding-left: 0px;
    padding-right: 0px;

    .card {
      background: transparent;
      box-shadow: none;
      border: none;
    }

    .font20 {
      font-size: 14px;
    }

    .customCardPadding {
      padding: 5px 10px 10px 10px !important;
    }
  }

  .motorCycleIcon,
  .scooterIcon,
  .premiumBikeIcon {
    height: 50px;
  }

  .motorCycleIcon {
    width: 83px;
  }

  .scooterIcon {
    width: 80px;
  }

  .premiumBikeIcon {
    width: 92px;
  }

  .carousel .thumbs-wrapper {
    margin: 0px 6px;
  }

  .carousel .thumb {
    width: 50px;
  }

  .lds-ring {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    height: 64px;
    z-index: 10;
    border-radius: 5px;
    background-color: #000;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 51px;
      height: 51px;
      margin: 6px;
      border: 6px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .storeLocatorlftBlock {
    .nav-link {
      margin: 0;
      padding: 11px 8px;
    }
  }

  .storeLocatorRightBlock .mapWrap {
    margin-bottom: 15px;
  }

  #bikesCategory .card:hover {
    box-shadow: none;
  }

  .viewAllBtn {
    position: relative;
    bottom: -11px;
    float: right;
  }
}

@media (max-width: 470px) {
  .visibleMob {
    display: inline;
  }

  .pdpRightBlock .credrBFGStoreIcon {
    width: 100px;
    height: 20px;
  }

  .pdpRightBlock .credrpartnericon {
    width: 100px;
    height: 22px;
  }

  .pdpRightBlock .credrDirectIcon {
    width: 100px;
    height: 22px;
  }

  .searchBar {
    width: 94%;
    //margin-left: 30px;

    .searchInput {
      width: 248px;
    }
  }

  #popularUsedBikes,
  #customerLike {
    .cardBikeImgWrapper {
      img {
        height: 209px;
        object-fit: cover;
      }
    }
  }

  .page-link {
    padding: 5px 8px;
    line-height: 20px;
    font-size: 12px;
  }

  #countDown h3.font22 {
    font-size: 14px;
  }

  .mobCustomPadding {
    &:nth-child(odd) {
      padding-left: 5px;
    }

    &:nth-child(even) {
      padding-right: 5px;
    }
  }

  .mobCustomPaddingRight {
    padding-right: 5px;
  }

  .mobCustomPaddingLeft {
    padding-left: 5px;
  }

  .fdMobCardBody {
    padding: 15px 8px;
  }
}

@media (max-width: 440px) {
  .page-content-ads.selected {
    min-height: 625px;
  }
  .nav-ads {
    height: 59px !important;
  }
  .navbar-brand-ads {
    width: 102px;
    height: 28px;
    padding-top: 0;
    margin-top: 1px;
  }
  .wrapperMobAds {
    margin-top: -66px;
  }
}



@media(max-width: 360px) {
  .pdpRightBlock .credrBFGStoreIcon {
  float: left !important;
  width: 95px;
    height: 24px;
    margin-top: 5px;
  }

  .pdpRightBlock .credrpartnericon {
    float: left !important;
    width: 95px;
      height: 24px;
      margin-top: 5px;
    }

    .pdpRightBlock .credrDirectIcon {
      float: left !important;
    width: 95px;
      height: 24px;
      margin-top: 5px;
    }
}

@media (max-width: 400px) {
  .credrBfGStore {
    width: 80px;
    height: 20px;
  }

  .credrpartner {
    width: 80px;
    height: 20px;
  }

  .credrDirect{
    width: 80px;
    height: 20px;
  }


  .footer .socialIcon {
    bottom: 12px;
  }

  .bikeListCard {
    .cardBikeImgWrapper {
      img {
        height: 230px;
        object-fit: cover;
      }
    }
  }

  .nav-link {
    margin: 0 4px;
    padding: 11px 10px;
  }

  .budgetBikes .nav-item div.nav-link {
    margin: 0 15px;
  }

  #bikesCategory {
    padding: 0 15px;
  }

  #countDown h5.font22 {
    font-size: 14px;
  }

  #countDown .ratings-icon {
    width: 90px !important;
    height: 18px !important;
  }

  .navbarRight .contactIconMobile {
    // width: 23px;
    // height: 23px;
    // margin-right: 40px;
}
}

label.MuiFormLabel-root.Mui-focused {
  color: #333;
}

label.MuiFormLabel-root.Mui-error {
  color: #c00;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #333 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #333 !important;
}

@media (max-width: 699.98px) {
  .nav-bg {
    height: 58px;
  }

  .nav-ads {
    height: 125px;
  }
}

.errorWrapper {
  max-width: 856px;
  width: 100%;
  margin: 0px auto;
  margin-top: 60px;
}

.colorRed {
  color: #dc292f;
}

.errorContentBlock {
  margin-top: 80px;
}

svg.MuiCircularProgress-svg {
  width: 25px;
  margin-bottom: 0;
}

.sellBtn.btn-primary:disabled,
.btn.btn-primary:disabled {
  background-color: #f1a3a6;
  border-color: #f1a3a6;
}

.MuiSlider-root.slider-track {
  color: #da2128;
}

.MuiSlider-thumb.slider-thumb {
  height: 14px;
  width: 14px;
  border: 2px solid #d9d9d9;
}

.MuiSlider-rail.slider-rail {
  height: 4px;
  border-radius: 6px;
}

.MuiSlider-track.slider-track {
  height: 4px;
}

.MuiExpansionPanel-root.Mui-expanded.credr-pannel {
  margin: 0px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.loader-backdrop {
  height: 100vh;
  width: 100vw;
  background-color: rgba(231, 242, 242, 0.75);
  position: fixed;
  z-index: 999999;
  display: none;

  &.show {
    display: block;
  }
}

.verloop-button {
  z-index: 99999 !important;
}

#root.loader-show {
  height: 100vh;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: rgba(231, 242, 242, 0.75);
  position: fixed;
  z-index: 999999;
}

.fallback-loader-backdrop.show {
  height: calc(100vh + 116px);
  width: 100vw;
  background-color: rgba(231, 242, 242, 0.75);
  position: fixed;
  z-index: 999999;
  display: none;
  margin-top: -116px;

  &.show {
    display: block;
  }
}

.slider {
  height: 100%;
  width: 100%;

  .adBanner {
    width: 100% !important;
    position: relative;
    cursor: none;
    display: inline-block !important;

    img {
      width: 100%;
    }
  }

  .list {
    width: 100%;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    img {
      width: 100%;
    }

    &:hover {
      -webkit-filter: grayscale(45%);
      -moz-filter: grayscale(45%);
      -o-filter: grayscale(45%);
      -ms-filter: grayscale(45%);
      filter: grayscale(45%);
    }
  }

  .listadd {
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;

    img {
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 10px;
    display: none !important;
  }

  .slick-prev {
    left: 20px;
    border: none;
    outline: none;
    height: 40px;
    width: 40px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/left-arrow.svg);
    background-size: 100%;
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    color: rgb(22, 20, 20);
    font-size: 25px;
    display: none;
  }

  .slick-next {
    right: 20px;
    border: none;
    outline: none;
    height: 40px;
    width: 40px;
    background: url(https://dqbkg06i6c7gd.cloudfront.net/assets/images/right-chevron.svg);
    background-size: 100%;
  }

  .custom-btn {
    position: absolute;
    bottom: 32%;
    left: 160px;
    padding-top: 10px;
  }
}


.searchBar-bg-container{
  display: flex;
}
.grecaptcha-badge { 
  visibility: hidden !important;
}