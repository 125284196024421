.buyAtHome {

    .MuiBadge-anchorOriginTopRightRectangle {
        top: -11px;
        right: 17px;
        transform: scale(1) translate(50%, -50%);
        transform-origin: 100% 0;
    }

    .MuiBadge-colorSecondary {
        font-family: 'ProximaNovaRegular';
        font-size: 10px;
        line-height: 15px;
        color: #fff;
        background-color: #dc292f !important;
        border: #dc292f !important;
        height: 15px;
        padding: 0px 7px;
    }
}