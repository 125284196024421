.chip-root {
  padding: 5px 15px 4px 15px;
  display: inline-block;
  background-color: #F4F7FD;
  border-radius: 20px;
  border: 1px solid #35385D;
  margin: 5px 0;
  font-size: 14px;
  font-family: "ProximaNovaRegular";
  color: #333;
  line-height: 21px;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 10px;
  }

  // p {
  //   font-size: 14px;
  //   font-family: "ProximaNovaRegular";
  //   color: #333;
  //   margin: 0;
  //   line-height: 21px;
  //   text-decoration: none;
  // }
}

a.chip-root{
  text-decoration: none;

  &:hover{
    color: #333;
  }
}