#TriggerWrap {
    width: 100%;
    height: 116px;
    position: fixed;
    z-index: 1000;
    top: 0;
    background-color: #fff;

        .credrLogoPadd {
            padding-top: 25px;
            padding-bottom: 25px;
            padding-left: 5px;
            padding-right: 8px;
        }

        .installButton {
            border: none;
            border-radius: 4px;
            background-color: #4DBD74;
            margin-top: 30px;
            color: #fff;
            padding: 10px;
            text-transform: uppercase;
            font-size: 12px;
        }

        .dismissButton {
            margin-top: 8px;
            border: none;
            color: #9d9d9d;
            background-color: transparent;
            text-transform: uppercase;
            font-size: 12px;
        }

        .buttonWrap{
            text-align: center;
        }

        .p {
            margin-bottom: 0;
            margin-top: 5px;
        }

        .wrapStar{
            position: relative;
            left: 70px;
            bottom: 77px;
        }
    }