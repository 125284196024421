#zestFooter{
  .newBuyLandingFooter {
      width: 100%;
      // max-width: 1540px;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      background-color: #fafafa;
      padding: 60px 0;
  }

  .rectRatings {
    width: 100%;
    max-width: 1129px;
    background-color: #4b2167;
    border-radius: 20px;
    margin: 0px auto;
    position: relative;
    bottom: 58px;
}

.bookNow {
  max-width: 175px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #da2128;  
  border-radius: 22px;
  color: #ffffff;
  border: none;
  padding: 13px 50px 13px 48px;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 20px;
  font-family: 'ProximaNovaRegular';
}

.iconsWrap {
  padding: 20px 0;
  font-size: 20px;
  text-transform: capitalize;
  font-family: 'ProximaNovaSemiBold';
  color: #fff;
  margin: 0px auto;
  text-align: center;

  &.right {
      padding-left: 100px !important;
  }
}

br.responsive {
  display: none;
}

  .header__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(157deg, #682381 53%, #2e1e4d 98%);
    transform: skewY(-2deg);
    transform-origin: top right;
  }

  .addressWrap {
    position: relative;
    margin: 0px auto;
    max-width: 500px;
    text-align: center;
    color: #ffffff;
  }

  .address {
    max-width: 500px;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.44;
    text-align: center;
    margin: 0px auto;
    padding-top: 13px;
    position: relative;
  }
}

@media (max-width: 1199px) {
  #zestFooter .rectRatings {
    max-width: 1000px;
  }

  #zestFooter .header__bg {
    transform: skewY(-3deg);
  }
}

@media (max-width: 1039px) {
  #zestFooter .rectRatings {
    max-width: 825px;
  }
  #zestFooter .bookNow {
    margin-top: 10px;
  }
  #zestFooter .header__bg {
    transform: skewY(-5.3deg);
  }
}

@media (max-width: 859px){
  #zestFooter .rectRatings {
      max-width: 760px;
  }
}

@media (max-width: 767px) {
  #zestFooter .rectRatings {
    max-width: 699px;
    border-radius: 4px;
}

 #zestFooter br.responsive {
  display: inline;
}

#zestFooter .iconsWrap {
  line-height: 1.3;
  padding: 15px 0;
}
#zestFooter .header__bg {
  transform: skewY(
-8deg
);
}
}
@media (max-width: 717px) {
  #zestFooter .rectRatings {
    max-width: 579px;
  }
}

@media (max-width: 599px) {
  #zestFooter .rectRatings {
    max-width: 480px;
}
#zestFooter .iconsWrap{
  font-size: 14px;
}
#zestFooter .bookNow {
  padding: 10px 30px ;
}
}

@media (max-width: 499px) {
  #zestFooter .rectRatings {
    max-width: 380px;
    margin-top: 29px;
  }

  #zestFooter .ratings-icon {
    width: 90px;
  }

  #zestFooter .address{
    padding: 13px 15px 0 15px;
  }
  #zestFooter .header__bg {
    transform: skewY(-12deg);
  }
  #zestFooter .newBuyLandingFooter {
    padding: 30px 0;
  }
}

@media (max-width: 400px) {
  #zestFooter .rectRatings {
    max-width: 337px;
}
#zestFooter .header__bg {
  transform: skewY(
-14deg
);
}
}

@media (max-width: 358px) {
  #zestFooter .rectRatings {
    max-width: 305px;
}
}