.lowerfooter {
  width: 100%;
  background-color: #5C5C5C;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 13px;

  p {
    padding: 0;
    margin: 0;
  }
}